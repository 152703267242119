import React from 'react'
import SearchVehicleForm from '../../components/rentals/searchVehicleForm'
import carRentImg from "../../assets/images/carRent2.jpg"
import listCarImg from "../../assets/images/listCarImg.avif"
import CustomFooter from '../../components/layouts/customFooter'
import GetTheAppsComponent from '../../components/shared/getTheAppsComponent'
import OurFleet from '../../components/rentals/ourFleet'
import CustomStickyNav from '../../components/layouts/customStickyNav'
import { StorageBox } from '../../helpers/stroage'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'

const CarRentalsPage = () => {

    const token = StorageBox.getAccessToken()
    const user_data = StorageBox.retrieveUserData()
    const navigate = useNavigate()


    //handleOpenDashboard
    const handleOpenDashboard = () => {
        if (!token) {
            message.info('You need to login to access this feature!')
        } else {
            navigate(`/car-rentals/owner/dashboard/${user_data?.id}`)
        }
    }


    return (
        <>
            <div className="h-screen w-full">
                <div className="w-full">
                    <CustomStickyNav rentalsActive />

                    {/* content */}
                    <div className="content-container">
                        {/* banner */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px]">
                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-20 2xl:py-10 xl:py-10 lg:py-10 md:py-10 items-center">
                                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[500px] rounded-[10px] object-cover' src={carRentImg} alt="" />
                                </div>

                                <div className="w-full mt-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0">
                                    <p className='text-[46px] leading-[50px] font-[600]'>Rent the best cars, drive with style</p>

                                    <div className="mt-10">
                                        <SearchVehicleForm />
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* fleet */}
                        <OurFleet />

                        {/* agency */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] mt-10">
                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-20 py-10 items-center">
                                <div className="w-full">
                                    <p className='text-[46px] leading-[50px] font-[600]'>Are You a Car Owner or an Agency?</p>
                                    <p className='text-[16px] font-[400] mt-10'>
                                        Turn your idle vehicles into a revenue stream by listing them for rent with Buzlift. Whether you're an individual car owner or an agency managing a fleet, we offer a seamless platform to showcase your vehicles to a broad audience
                                    </p>

                                    <div className="mt-10">
                                        <button className='2xl:w-[250px] xl:w-[250px] lg:w-[250px] md:w-[200px] h-[50px] px-5 rounded-[30px] bg-defaultBlue text-white' onClick={handleOpenDashboard}>
                                            <p>List My Vehicle</p>
                                        </button>
                                    </div>
                                </div>

                                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[500px] rounded-[10px] object-cover' src={listCarImg} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* get the apps */}
                        <GetTheAppsComponent />

                        <div>
                            <CustomFooter />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarRentalsPage
