import React from 'react'
import { QRCode } from 'antd'
import logoImg from "../../assets/images/BUZS.png"


const GetTheAppsComponent = () => {
    return (
        <div className="w-full bg-defaultBlue bg-opacity-[10%] 2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] py-20 mt-10">
            <p className='text-[32px] font-[600]'>The apps make it really simple</p>

            <div className="w-full mt-8 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-10">
                {/* android */}
                <div className="w-full flex gap-5 items-center bg-white p-5 cursor-pointer hover:shadow-md">
                    <div>
                        <QRCode
                            className='object-cover'
                            value="https://play.google.com/store/games?hl=en"
                            icon={logoImg}
                            bordered={false}
                        />
                    </div>

                    <div className='text-black text-opacity-[80%]'>
                        <p className='text-[24px] font-[600]'>Buzlift Android App</p>
                        <p className='text-[16px] font-[400]'>Scan to download</p>
                    </div>
                </div>

                {/* ios */}
                <div className="w-full flex gap-5 items-center bg-white p-5 cursor-pointer hover:shadow-md 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
                    <div>
                        <QRCode
                            className='object-cover'
                            value="https://www.apple.com/app-store/"
                            icon={logoImg}
                            bordered={false}
                        />
                    </div>

                    <div className='text-black text-opacity-[80%]'>
                        <p className='text-[24px] font-[600]'>Buzlift iOS App</p>
                        <p className='text-[16px] font-[400]'>Scan to download</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetTheAppsComponent
