import React from 'react'
import { Select } from 'antd'


interface theProps {
    onChange?: (data: any) => void
}


const TransmissionTypeSelector = ({ onChange }: theProps) => {
    return (
        <>
            <Select
                className='h-[40px] w-full'
                options={[
                    { label: 'Manual', value: 'Manual' },
                    { label: 'Automatic', value: 'Automatic' },
                    { label: 'Semi Automatic', value: 'Semi Automatic' }
                ]}
                onChange={onChange}
                placeholder="Choose"
            />
        </>
    )
}

export default TransmissionTypeSelector
