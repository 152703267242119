import React, { useState } from 'react'
import { Checkbox, Drawer, Form, Input, message, Spin, DatePickerProps } from 'antd'
import { HiXMark } from 'react-icons/hi2'
import { useForm } from 'antd/es/form/Form'
import { BaseServices } from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import { BsFuelPump } from 'react-icons/bs'
import { GiGearStickPattern } from 'react-icons/gi'
import { FiUserCheck } from 'react-icons/fi'
import { TbEngine } from "react-icons/tb";
import DateTimePicker from '../shared/dateTimePicker'


interface drawerProps {
    isOpened: boolean
    selectedCar: null
    handleClose: () => void
    handleReFetch: () => void
}


const BookCarDrawer = ({ isOpened, selectedCar, handleClose, handleReFetch }: drawerProps) => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [includeDriver, setIncludeDriver] = useState<any>(false)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    //onStartDateChange
    const onStartDateChange: DatePickerProps['onChange'] = (_, dateStr: any) => {
        if (dateStr) {
            console.log('onChangeStart:', new Date(dateStr).toISOString());
            setStartDate(new Date(dateStr).toISOString())
        }
    };

    //onEndDateChange
    const onEndDateChange: DatePickerProps['onChange'] = (_, dateStr: any) => {
        if (dateStr) {
            console.log('onChangeEnd:', new Date(dateStr).toISOString());
            setEndDate(new Date(dateStr).toISOString())
        }
    };


    //handleOnSubmit
    const handleOnSubmit = async (values: any) => {
        console.log("___formValues:", values)

        let data = new FormData();
        data.append('car', "f2e74fb8-f8d4-49f4-ae01-01ddb752fbfe");
        data.append('rental_start', startDate);
        data.append('rental_end', endDate);
        data.append('latitude', "0");
        data.append('longitude', "0");
        data.append('needs_driver', includeDriver);

        setIsBusy(true)

        try {
            const response = await BaseServices.post(global_variables.book_vehicle, data)
            console.log("bookVehRes:", response)
            setIsBusy(false)
            handleReFetch()
            handleClose()
        } catch (error: any) {
            console.log("bookVehErr:", error)
            setIsBusy(false)
            message.error(error?.response?.data?.error || "An error occured! Please retry.")
        }

    }

    return (
        <>
            <Drawer open={isOpened} onClose={handleClose} placement='right' closeIcon={false} maskClosable={false} keyboard={false} footer={null}>
                <Spin spinning={isBusy}>
                    <div className="flex justify-between items-center">
                        <p className='text-[18px] font-[500]'>Rent a vehicle</p>

                        <button className='h-8 w-8 flex justify-center items-center hover:rounded hover:bg-gray-50 hover:text-red-500' onClick={handleClose}>
                            <HiXMark className='h-5 w-5' />
                        </button>
                    </div>

                    <hr className='my-2' />

                    {/* car details */}
                    <div className="p-5">
                        <img className='h-[150px] w-full object-cover' src="https://d2ivfcfbdvj3sm.cloudfront.net/7fc965ab77efe6e0fa62e4ca1ea7673bb25f44550a1e3d8e88cb10/stills_0640_png/MY2022/15541/15541_st0640_116.png" alt="" />
                    </div>

                    <div className="mt-3 px-5 pb-10">
                        <div className="flex justify-between items-center">
                            <div>
                                <p className='text-[18px] font-[600]'>Honda</p>
                                <p className='text-gray-400 text-[12px]'>Accord</p>
                            </div>

                            <div>
                                <p className='text-[18px] font-[600]'>GHS 450</p>
                                <p className='text-gray-400 text-[12px]'>Per Day</p>
                            </div>
                        </div>


                        <div className="mt-3 flex gap-3 items-center">
                            <div>
                                <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                    <FiUserCheck className='h-5 w-5' />
                                    <p className='text-[10px]'>5 People</p>
                                </div>
                            </div>

                            <div>
                                <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                    <GiGearStickPattern className='h-5 w-5' />
                                    <p className='text-[10px]'>Auto</p>
                                </div>
                            </div>

                            <div>
                                <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                    <BsFuelPump className='h-5 w-5' />
                                    <p className='text-[10px]'>Petrol</p>
                                </div>
                            </div>

                            <div>
                                <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                    <TbEngine className='h-5 w-5' />
                                    <p className='text-[10px]'>2.0L</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* form */}
                    <div className="h-full overflow-y-auto">
                        <Form
                            form={form}
                            onFinish={handleOnSubmit}
                            layout='vertical'
                        >
                            <Form.Item label="Pick Up Location" name="location" rules={[{ required: true, message: 'Required field!' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: Lapaz Market' />
                            </Form.Item>

                            <Form.Item label="Start Date & Time" name="rental_start" rules={[{ required: true, message: 'Required field!' }]}>
                                <DateTimePicker onChange={onStartDateChange} />
                            </Form.Item>

                            <Form.Item label="End Date & Time" name="rental_end" rules={[{ required: true, message: 'Required field!' }]}>
                                <DateTimePicker onChange={onEndDateChange} />
                            </Form.Item>

                            {/* driver */}
                            <Form.Item name="needs_driver">
                                <Checkbox onChange={(val) => setIncludeDriver(val?.target?.checked)}>
                                    I will be needing a driver
                                </Checkbox>
                            </Form.Item>

                            {/* button */}
                            <div className="w-full mt-8">
                                <button className='h-[40px] w-full bg-defaultBlue text-white rounded'>
                                    <p>Rent Now</p>
                                </button>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Drawer>
        </>
    )
}

export default BookCarDrawer
