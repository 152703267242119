import { useState, useEffect } from "react";
import axios from "axios";
import PinInput from "react-pin-input";
import Lottie from "lottie-react";
import emailAnimation from "../../assets/lottie/email_animation.json"
import { LoadingOutlined } from '@ant-design/icons';
import { Modal, notification, Spin } from "antd";
import { API_BASE_URL } from "../../helpers/constants";
import { global_variables } from "../../helpers/globalVariables";
import { StorageBox } from "../../helpers/stroage";

interface modalProps {
  openModal: boolean;
  handleCloseModal: () => void;
  requestId: any;
}

const InputPinModal = ({ openModal, handleCloseModal, requestId }: modalProps) => {

  const [isVerifying, setIsVerifying] = useState(false);
  const [inputPin, setInputPin] = useState("");



  // runVerification
  const runVerification = (value: any) => {

    let data = JSON.stringify({
      "otp": inputPin,
      "requestId": requestId
    });

    setIsVerifying(true);

    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: `${API_BASE_URL}${global_variables.verify_otp}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log("verifyRes:", response?.data);
        StorageBox.saveAccessToken(response?.data?.token?.access)
        StorageBox.saveUserData(response?.data?.user)
        notification.success({ message: 'Success', description: 'You have successfully verified your account' });
        setTimeout(() => {
          window.location.href = "/"
        }, 1000)
      })
      .catch((error: any) => {
        console.log("verifyErr:", error);
        notification.error(error?.response?.data?.detail || error?.response?.data?.error || "An unknown error occured. Try again")
        setIsVerifying(false);
        handleCloseModal();
      });

  };


  return (
    <>
      <Modal width={800} centered open={openModal} onCancel={handleCloseModal} footer={null} maskClosable={false} closeIcon={false}>

        <div className="lg:grid lg:grid-cols-2">
          <div className="hidden lg:block">

            <Lottie animationData={emailAnimation} loop={true} />
          </div>
          <div className="p-[20px] flex justify-center items-center flex-col">
            <p className="text-center">
              We have sent a 5 digits pin to your email, enter it below and
              click confirm.
            </p>

            <div className="mt-3">
              <PinInput
                length={5}
                initialValue=""
                secret
                secretDelay={100}
                onChange={(value) => setInputPin(value)}
                type="numeric"
                inputMode="number"
                style={{ padding: "10px" }}
                inputStyle={{ borderColor: "red" }}
                inputFocusStyle={{ borderColor: "#165b4f" }}
                onComplete={(value) => runVerification(value)}
                autoSelect={true}
                disabled={isVerifying}
              />
            </div>

            {isVerifying ? (
              <div className="mt-3">
                <Spin indicator={<LoadingOutlined style={{ fontSize: 28, color: '#000' }} spin />} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

      </Modal>
    </>
  );
};

export default InputPinModal;
