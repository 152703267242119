import React, { useState } from 'react'
import { GiGearStickPattern } from "react-icons/gi";
import { FiUser } from "react-icons/fi";
import { BsFuelPump } from "react-icons/bs";
import BookCarDrawer from './bookCarDrawer';


const CarListingCard = () => {

    const [openRentDrawer, setOpenRentDrawer] = useState(false)

    return (
        <>
            <div className='w-full shadow-md bg-white rounded-[40px] cursor-pointer hover:shadow-lg mb-5 2xl:mb-0 xl:mb-0 lg:mb-0 md:mb-0' onClick={() => setOpenRentDrawer(true)}>
                <div className="p-5">
                    <img className='h-[150px] w-full object-cover' src="https://d2ivfcfbdvj3sm.cloudfront.net/7fc965ab77efe6e0fa62e4ca1ea7673bb25f44550a1e3d8e88cb10/stills_0640_png/MY2022/15541/15541_st0640_116.png" alt="" />
                </div>

                <div className="mt-3 px-5 pb-10">
                    <div className="flex justify-between items-center">
                        <div>
                            <p className='text-[18px] font-[600]'>Honda</p>
                            <p className='text-gray-400 text-[12px]'>Accord</p>
                        </div>

                        <div>
                            <p className='text-[18px] font-[600]'>GHS 450</p>
                            <p className='text-gray-400 text-[12px]'>Per Day</p>
                        </div>
                    </div>


                    <div className="mt-3 flex gap-3 items-center">
                        <div>
                            <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                <FiUser className='h-5 w-5' />
                                <p className='text-[10px]'>5 People</p>
                            </div>
                        </div>

                        <div>
                            <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                <GiGearStickPattern className='h-5 w-5' />
                                <p className='text-[10px]'>Auto</p>
                            </div>
                        </div>

                        <div>
                            <div className='flex flex-col items-center justify-center h-14 w-14 rounded-[10px] bg-defaultBlue bg-opacity-[10%] text-defaultBlue p-1'>
                                <BsFuelPump className='h-5 w-5' />
                                <p className='text-[10px]'>Petrol</p>
                            </div>
                        </div>

                        <div className='w-full'>
                            <button className='h-14 w-full px-1 rounded-t-[20px] rounded-bl-[20px] bg-defaultBlue text-white' onClick={() => setOpenRentDrawer(true)}>
                                <p className='text-[12px]'>Rent Now</p>
                            </button>
                        </div>
                    </div>
                </div>
            </div>


            {
                openRentDrawer && <BookCarDrawer isOpened={openRentDrawer} selectedCar={null} handleClose={() => setOpenRentDrawer(false)} handleReFetch={() => { }} />
            }
        </>
    )
}

export default CarListingCard
