import React from 'react'
import driverImg from "../../assets/images/driverImg.jpg"


const DriverBannerComponent = () => {
    return (
        <div className="w-full bg-defaultBlue bg-opacity-[10%] 2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] py-14 mt-10">
            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 items-center gap-20">
                <div className="w-full">
                    <img className='h-[400px] rounded-[10px] object-cover' src={driverImg} alt="" />
                </div>

                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
                    <p className='text-[32px] font-[600]'>Drive with Buzlift: Empower Your Journey</p>
                    <p className='text-[16px] font-[400] mt-8'>
                        Join the Buzlift community and enjoy the freedom of flexible working hours, competitive earnings, and the opportunity to meet new people every day. Whether you're looking for full-time income or a side hustle, driving with Buzlift puts you in the driver's seat—literally. Plus, with our dedicated driver support and a platform that prioritizes your safety, you can drive with confidence and peace of mind.
                    </p>

                    <div className="mt-8">
                        <button className='2xl:w-[250px] xl:w-[250px] lg:w-[250px] md:w-[200px] h-[50px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                            <p>Become A Driver</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverBannerComponent