import { verify } from "crypto";

export const global_variables = {
  //auth
  sign_in: "/accounts/send-otp",
  verify_otp: "/accounts/verify-otp",
  update_profile: "/accounts/update-user",

  create_vehicle: "/car_rental/vehicles/",
  book_vehicle: "/car_rental/rentals/",
};
