import React from 'react'
import { GiReceiveMoney } from "react-icons/gi";
import { HiOutlineArrowRight } from "react-icons/hi2";


const OverallEarningsCard = () => {
    return (
        <>
            <div className="w-full bg-white shadow-md rounded-[10px] p-5">
                <div className="flex justify-between items-center">
                    <div className='flex gap-2 items-center'>
                        <div className='h-8 w-8 bg-defaultBlue bg-opacity-[10%] rounded-full flex justify-center items-center text-defaultBlue'>
                            <GiReceiveMoney className='h-5 w-5' />
                        </div>
                        <p className='text-[14px] font-[500]'>Earnings</p>
                    </div>

                    <div>
                        <button className='h-5 w-5 text-gray-500 mt-2'>
                            <HiOutlineArrowRight />
                        </button>
                    </div>
                </div>
                <hr className='my-2' />

                <div className="w-full mt-3">
                    <p className='text-[16px] font-[600]'>GHS 1,570.21</p>
                </div>
            </div>
        </>
    )
}

export default OverallEarningsCard
