import React, { useState } from 'react'
import { Checkbox, Drawer, Form, Input, message, Spin } from 'antd'
import { HiXMark } from 'react-icons/hi2'
import { useForm } from 'antd/es/form/Form'
import TransmissionTypeSelector from '../shared/transmissionTypeSelector'
import FuelTypeSelector from '../shared/fuelTypeSelector'
import { BaseServices } from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'


interface drawerProps {
    isOpened: boolean
    handleClose: () => void
    handleReFetch: () => void
}


const ListCarDrawer = ({ isOpened, handleClose, handleReFetch }: drawerProps) => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [airCon, setAirCon] = useState<any>(false)
    const [heating, setHeating] = useState<any>(false)
    const [includeDriver, setIncludeDriver] = useState<any>(false)
    const [carImgFile, setCarImgFile] = useState<any>(null)
    const [carImgUrl, setCarImgUrl] = useState<any>("")

    //onImgaeChange
    const onImgaeChange = (e: any) => {
        const file = e?.target?.files?.[0]
        setCarImgFile(file)
        const imgUrl = URL.createObjectURL(file)
        setCarImgUrl(imgUrl)
    }


    //handleOnSubmit
    const handleOnSubmit = async (values: any) => {
        console.log("___formValues:", values)

        let data = new FormData();
        data.append('make', values?.make);
        data.append('model', values?.model);
        data.append('year', values?.year);
        data.append('license_plate', values?.license_plate);
        data.append('daily_rate', values?.daily_rate);
        data.append('transmission_type', values?.transmission_type);
        data.append('fuel_type', values?.fuel_type);
        data.append('engine_capacity', values?.engine_capacity);
        data.append('include_driver', includeDriver);
        data.append('air_condition', airCon);
        data.append('heating', heating);
        data.append('color', values?.color);
        data.append('image', carImgFile);

        setIsBusy(true)

        try {
            const response = await BaseServices.post(global_variables.create_vehicle, data)
            console.log("createVehRes:", response)
            setIsBusy(false)
            handleReFetch()
            handleClose()
        } catch (error: any) {
            console.log("createVehErr:", error)
            setIsBusy(false)
            message.error(error?.response?.data?.error || "An error occured! Please retry.")
        }

    }

    return (
        <>
            <Drawer open={isOpened} onClose={handleClose} placement='right' closeIcon={false} maskClosable={false} keyboard={false} footer={null}>
                <Spin spinning={isBusy}>
                    <div className="flex justify-between items-center">
                        <p className='text-[18px] font-[500]'>List New Vehicle</p>

                        <button className='h-8 w-8 flex justify-center items-center hover:rounded hover:bg-gray-50 hover:text-red-500' onClick={handleClose}>
                            <HiXMark className='h-5 w-5' />
                        </button>
                    </div>

                    <hr className='my-2' />

                    {/* form */}
                    <div className="h-full overflow-y-auto">
                        <Form
                            form={form}
                            onFinish={handleOnSubmit}
                            layout='vertical'
                        >
                            <Form.Item label="Make" name="make" rules={[{ required: true, message: 'Please input the vehicle make!' }]}>
                                <Input className='h-[40px] w-full' placeholder='Ex: Toyota' />
                            </Form.Item>

                            <div className="flex justify-between gap-5">
                                <Form.Item label="Model" name="model" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: Camry' />
                                </Form.Item>

                                <Form.Item label="Year" name="year" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: 2022' />
                                </Form.Item>
                            </div>

                            <div className="flex justify-between gap-5">
                                <Form.Item label="Plate No." name="license_plate" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: GR-454-24' />
                                </Form.Item>

                                <Form.Item label="Daily Rate" name="daily_rate" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: 350' />
                                </Form.Item>
                            </div>

                            <div className="flex justify-between gap-5">
                                <Form.Item className='w-full' label="Transmission" name="transmission_type" rules={[{ required: true, message: 'Required field!' }]}>
                                    <TransmissionTypeSelector onChange={(value) => console.log(value)} />
                                </Form.Item>

                                <Form.Item className='w-full' label="Fuel Type" name="fuel_type" rules={[{ required: true, message: 'Required field!' }]}>
                                    <FuelTypeSelector onChange={(value) => console.log(value)} />
                                </Form.Item>
                            </div>

                            <div className="flex justify-between gap-5">
                                <Form.Item label="Engine Capacity" name="engine_capacity" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: 2.5' />
                                </Form.Item>

                                <Form.Item label="Color" name="color" rules={[{ required: true, message: 'Required field!' }]}>
                                    <Input className='h-[40px] w-full' placeholder='Ex: White' />
                                </Form.Item>
                            </div>

                            <div className="flex gap-5">
                                <Form.Item name="air_condition">
                                    <Checkbox onChange={(val) => setAirCon(val?.target?.checked)}>
                                        Air Condition
                                    </Checkbox>
                                </Form.Item>

                                <Form.Item name="heating">
                                    <Checkbox onChange={(val) => setHeating(val?.target?.checked)}>
                                        Heating
                                    </Checkbox>
                                </Form.Item>
                            </div>


                            {/* question */}
                            <Form.Item name="include_driver">
                                <Checkbox onChange={(val) => setIncludeDriver(val?.target?.checked)}>
                                    Include a in this vehicle listing
                                </Checkbox>
                            </Form.Item>

                            {/* image */}
                            {
                                carImgUrl &&
                                <div className='mb-5'>
                                    <img className='h-[150px] w-full object-cover' src={carImgUrl} alt="" />
                                </div>
                            }
                            <Form.Item label="Vehicle Image" name="image" rules={[{ required: true, message: 'Upload vehicle image!' }]}>
                                <input className='' type="file" id='carImg' onChange={onImgaeChange} />
                            </Form.Item>

                            {/* button */}
                            <div className="w-full mt-8">
                                <button className='h-[40px] w-full bg-defaultBlue text-white rounded'>
                                    <p>Publish</p>
                                </button>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Drawer>
        </>
    )
}

export default ListCarDrawer
