import React, { useState } from 'react'
import carpoolImg from "../../assets/images/carpoolImg.jpg"
import { Form, message, notification, Spin } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { BaseLiftServices } from '../../helpers/baseServices'
import { global_variables } from '../../helpers/globalVariables'
import PhoneNumberInput from "react-phone-number-input";
import InputPinModal from './input-pin'


const SigninPage = () => {

    const [form] = useForm()
    const [isBusy, setIsBusy] = useState(false)
    const [openOTPModal, setOpenOTPModal] = useState(false)
    const [requestId, setRequestId] = useState<any>("")

    //handle sign in
    const onFormFinished = async (values: any) => {
        console.log("formValues:", values)

        let signin_info = {
            "phoneNumber": "phone_number"
        }
        console.log("signin_info:", signin_info)

        setIsBusy(true)

        try {
            const response = await BaseLiftServices.post(global_variables.sign_in, signin_info)
            console.log("signinRes:", response?.data)
            notification.info({ message: 'Verification', description: 'A verification code has been sent to your phone number. Enter the code to continue' })
            setRequestId(response?.data?.otp?.requestId)
            setOpenOTPModal(true)
            setIsBusy(false)
            form.resetFields()
        } catch (error: any) {
            console.log("signInErr:", error)
            message.error(error?.response?.data?.detail || error?.response?.data?.error || "An unknown error occured. Try again")
            setIsBusy(false)
        }
    }


    return (
        <>
            <div className="h-screen w-full overflow-hidden">
                <div className="2xl:grid xl:grid lg:grid md:grid grid-cols-2 w-full h-full">
                    {/* left */}
                    <div className="w-full h-full hidden 2xl:block xl:block lg:block md:block">
                        <img className='h-full w-full object-cover' src={carpoolImg} alt="" />
                    </div>

                    {/* right */}
                    <div className="w-full h-full flex justify-center items-center bg-gray-50 overflow-y-auto">
                        <div className="w-[450px] p-10">
                            <p className='text-[32px] font-[600]'>Welcome!</p>
                            <p className='mb-5 mt-2'>
                                Let's get you started in with your <span className='text-defaultBlue'>Phone Number</span>
                            </p>

                            <Spin spinning={isBusy}>
                                <Form
                                    form={form}
                                    layout='vertical'
                                    name="loginForm"
                                    onFinish={onFormFinished}
                                    disabled={isBusy}
                                >
                                    {/* <Form.Item name="username" label="Email" rules={[{ required: true, message: 'Please enter your email!' }, { type: 'email', message: 'Please enter a valid email!' }]}>
                                        <Input className='h-[40px]' placeholder='example@gmail.com' />
                                    </Form.Item>

                                    <Form.Item name="password" label="Password" rules={[{ required: true, message: 'Please enter your password!' }]}>
                                        <Input.Password className='h-[40px]' placeholder='123456' />
                                    </Form.Item>

                                    <div className="mt-5 w-full">
                                        <p>
                                            Forgot password?
                                            <span>
                                                <Link to={`${BUZZ_WEBSITE}/forgot-password`} target='_blank' className='font-[600] text-defaultBlue ml-2'>Reset Password</Link>
                                            </span>
                                        </p>
                                    </div> */}
                                    <Form.Item name='phone_number' rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                        <PhoneNumberInput
                                            onChange={() => { }}
                                            defaultCountry="GH"
                                            countryOptionsOrder={["GH", "NG"]}
                                            className="w-full border border-defaultBlue border-opacity-[10%] rounded-[5px] p-[10px] bg-white"
                                            numberInputProps={{
                                                className: "focus:outline-none",
                                                placeholder: "Enter your phone number"
                                            }}
                                        />
                                    </Form.Item>

                                    <Form.Item className='mt-10'>
                                        <button className='h-[40px] w-full rounded bg-gradient-to-r from-blue-600 to-defaultBlue text-white'>
                                            <p>Submit</p>
                                        </button>
                                    </Form.Item>
                                </Form>
                            </Spin>

                            {/* <div className="mt-5 w-full">
                                <p>
                                    Don't have an account?
                                    <span>
                                        <Link to={`${BUZZ_WEBSITE}/signup`} target='_blank' className='font-[600] text-defaultBlue ml-2'>Create a Buzsquare Account</Link>
                                    </span>
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>


            {/* input pin modal */}
            {openOTPModal ? (
                <InputPinModal
                    openModal={openOTPModal}
                    handleCloseModal={() => setOpenOTPModal(false)}
                    requestId={requestId}
                />
            ) : (
                <></>
            )}
        </>
    )
}

export default SigninPage
