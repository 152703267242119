import React from 'react'
import { DatePicker } from 'antd';


interface theProps {
    onChange: (_: any, dateStr: any) => void
}


const DateTimePicker = ({ onChange }: theProps) => {
    return (
        <>
            <DatePicker
                className='h-[40px] w-full'
                showTime
                showSecond={false}
                use12Hours
                onChange={onChange}
            />
        </>
    )
}

export default DateTimePicker
