import React from 'react'
import { Empty, Tag } from 'antd';
import DataTable from 'react-data-table-component';
import { BsPencilSquare, BsTrash } from 'react-icons/bs';


const CarListingsTable = () => {


    //templateList
    const templateList = [
        {
            name: "Honad Civic",
            image: "https://car-images.bauersecure.com/wp-images/2301/honda-civic-93.jpg",
            number: "GC 547 24",
            price: "GHS 250",
            status: "Rented"
        },
        {
            name: "Honad Accord",
            image: "https://car-images.bauersecure.com/wp-images/2301/honda-civic-93.jpg",
            number: "AX 1247 24",
            price: "GHS 350",
            status: "Available"
        },
        {
            name: "Mercedes G63",
            image: "https://www.motortrend.com/uploads/2021/12/2021-Mercedes-AMG-G63-27.jpg",
            number: "GC 47 24",
            price: "GHS 850",
            status: "Available"
        },
        {
            name: "Honad Accord",
            image: "https://car-images.bauersecure.com/wp-images/2301/honda-civic-93.jpg",
            number: "AX 1247 24",
            price: "GHS 350",
            status: "Available"
        },
        {
            name: "Honad Accord",
            image: "https://car-images.bauersecure.com/wp-images/2301/honda-civic-93.jpg",
            number: "AX 1247 24",
            price: "GHS 350",
            status: "Available"
        }
    ]

    //table columns
    const columns: any = [
        {
            name: "",
            cell: (row: any) => <img className='h-10 w-10 object-cover rounded-full' src={row?.image} alt="" />,
            width: '10%',
        },
        {
            name: <p className='text-[14px] font-[500]'>Car Name</p>,
            cell: (row: any) => row?.name,
        },
        {
            name: <p className='text-[14px] font-[500]'>Car Number</p>,
            cell: (row: any) => row?.number,
        },
        {
            name: <p className='text-[14px] font-[500]'>Price /day</p>,
            cell: (row: any) => row?.price,
        },
        {
            name: <p className='text-[14px] font-[500]'>Status</p>,
            cell: (row: any) => row?.status === "Rented" ? <Tag color='success'>{row?.status}</Tag> : <Tag color='blue'>{row?.status}</Tag>,
        },
        {
            name: "",
            cell: (row: any) => <div className='flex gap-2 items-center'>
                <button className='h-5 w-5' title='Edit'>
                    <BsPencilSquare />
                </button>
                <button className='h-5 w-5 text-red-500' title='Delete'>
                    <BsTrash />
                </button>
            </div>,
            width: '10%'
        },
    ];


    return (
        <>
            <DataTable
                columns={columns}
                data={templateList}
                // progressPending={isFetching}
                noDataComponent={<Empty description="There are no records to display" />}
                pagination
                highlightOnHover
                pointerOnHover
            />
        </>
    )
}

export default CarListingsTable
