// src/contexts/AppContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';


// Create the context with a default value
const GeneralContext = createContext<any>(null);

// Create a provider component
export const GeneralContextProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    const [reFetch, setReFetch] = useState(false)

    //onload
    useEffect(() => {

    }, [])

    return (
        <GeneralContext.Provider value={{ reFetch, setReFetch }}>
            {children}
        </GeneralContext.Provider>
    );
};

// Create a custom hook for easy access to the context
export const useGeneralContext = () => {
    const context = useContext(GeneralContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
