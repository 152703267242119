
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import logoImg from "../../assets/images/buzlift_logo.png"
import type { MenuProps } from 'antd';
import { Dropdown } from 'antd';
import { HiChevronDown } from "react-icons/hi";
import { HiOutlineBars3 } from "react-icons/hi2";
import { BUZZ_WEBSITE } from '../../helpers/constants';
import { StorageBox } from '../../helpers/stroage';
import { LuLayoutDashboard } from 'react-icons/lu';


interface navProps {
    solid?: boolean
    rideActive?: boolean
    rentalsActive?: boolean
    busActive?: boolean
    deliveryActive?: boolean
}


const CustomStickyNav = ({ solid, rideActive, rentalsActive, busActive, deliveryActive }: navProps) => {

    const navigate = useNavigate()
    const token = StorageBox.getAccessToken()
    const user_data = StorageBox.retrieveUserData()

    const [navbar, setNavbar] = useState(false)

    const changeBackground = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };


    //onload
    useEffect(() => {
        console.log('Component mounted, adding scroll event listener');
        window.addEventListener('scroll', changeBackground);
        return () => {
            console.log('Component unmounted, removing scroll event listener');
            window.removeEventListener('scroll', changeBackground);
        };
    }, []);


    //more dropdown
    const profileMenu: MenuProps = {
        'items': [
            {
                key: '1',
                label: (
                    <Link to="#">
                        My Profile
                    </Link>
                ),
            },
            {
                type: 'divider'
            },
            {
                key: '2',
                label: (
                    <Link to="#" className='text-red-500' onClick={() => {
                        StorageBox.clearStorage()
                        navigate("/")
                    }}>
                        Sign Out
                    </Link>
                ),
            },
        ]
    }

    //more dropdown
    const items: MenuProps = {
        'items':
            [
                {
                    key: '1',
                    label: (
                        <Link to="#">
                            Book A Driver
                        </Link>
                    ),
                },
                {
                    key: '2',
                    label: (
                        <Link to="#">
                            Become A Driver
                        </Link>
                    ),
                },
                {
                    key: '3',
                    label: (
                        <Link to="#">
                            Carpooling Service
                        </Link>
                    ),
                },
                {
                    key: '4',
                    label: (
                        <Link to="#">
                            Flight Ticketing
                        </Link>
                    ),
                }
            ]
    }


    //navMenus
    const navMenus = [
        {
            name: 'Ride Hailing',
            route: '#',
            active: null
        },
        {
            name: 'Car Rentals',
            route: '/car-rentals',
            active: rentalsActive
        },
        {
            name: 'Bus Ride',
            route: '#',
            active: null
        },
        // {
        //     name: 'Delivery',
        //     route: '#',
        //     active: null
        // }
    ]


    return (
        <div className={`${navbar ? 'navbar active' : 'navbar'} w-full z-10 bg-defaultBlue ${solid && 'shadow-sm bg-white'}`}>
            <div className='w-full'>
                <div className="w-full flex justify-between items-center 2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] py-5 z-10">
                    <div>
                        <Link to="/">
                            <img className='w-[100px]' src={logoImg} alt="" />
                        </Link>
                    </div>

                    {/* middle */}
                    <div className="text-[14px] font-[500] 2xl:flex xl:flex hidden gap-5 items-center">
                        {
                            navMenus.map((menu, i) => (
                                <Link key={i} to={menu?.route} className={`h-[40px] px-[10px] flex justify-center items-center hover:bg-black hover:bg-opacity-[7%] hover:rounded-[30px] ${menu?.active && 'bg-black bg-opacity-[7%] rounded-[30px]'} ${solid ? 'text-black' : 'text-white'}`}>
                                    <p className='text-[14px] font-[600]'>{menu?.name}</p>
                                </Link>
                            ))
                        }

                        {/* <Dropdown menu={items}>
                            <Link to="#" onClick={(e) => e.preventDefault()} className={`py-2 px-[10px] flex gap-1 justify-center items-center hover:bg-black hover:bg-opacity-[7%] hover:rounded-[30px] ${solid ? 'text-black' : 'text-white'}`}>
                                <p className='text-[14px] font-[600]'>More</p>
                                <HiChevronDown className='h-5 w-5' />
                            </Link>
                        </Dropdown> */}
                    </div>

                    {/* right */}
                    <div className='2xl:flex xl:flex hidden gap-[10px] items-center'>
                        {
                            token ?
                                <>
                                    <button className='h-[40px] w-[40px] rounded text-white hover:bg-black hover:bg-opacity-[7%] hover:rounded flex justify-center items-center' title='Dashboard' onClick={() => navigate(`/car-rentals/owner/dashboard/${user_data?.id}`)}>
                                        <LuLayoutDashboard className='h-5 w-5' />
                                    </button>

                                    <Dropdown menu={profileMenu}>
                                        <button className='text-white'>
                                            <img className='h-10 w-10 object-cover rounded-full border border-white' src="https://avatar.iran.liara.run/public/boy?username=Ash" alt="" />
                                        </button>
                                    </Dropdown>
                                </>
                                :
                                <>
                                    <button className='py-2 px-[24px] rounded-[8px] bg-gradient-to-r from-defaultPink to-defaultOrange text-white hover:bg-black hover:bg-opacity-[7%] hover:rounded-[30px]' onClick={() => navigate("/login")}>
                                        <p className='text-[14px] font-[500]'>Sign in</p>
                                    </button>

                                    <Link to={`${BUZZ_WEBSITE}/signup`} target='_blank' className='py-2 px-[24px] rounded-[30px] text-black bg-white'>
                                        <p className='text-[14px] font-[500]'>Register</p>
                                    </Link>
                                </>
                        }
                    </div>

                    {/* mobile nav */}
                    <div className='2xl:hidden xl:hidden'>
                        <button className='h-10 w-10 border border-white rounded flex justify-center items-center text-white'>
                            <HiOutlineBars3 className='h-5 w-5' />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomStickyNav
