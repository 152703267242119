import React from 'react'
import logoImg from "../../assets/images/buzlift_logo.png"
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { FaXTwitter, FaWhatsapp } from "react-icons/fa6";



const CustomFooter = () => {
    return (
        <div className="w-full bg-defaultBlue 2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] py-14">
            <div className="w-full 2xl:grid xl:grid lg:grid md:grid 2xl:grid-cols-4 xl:grid-cols-4 lg:grid-cols-4 md:grid-cols-2 gap-10">
                {/* logo */}
                <div className="w-full">
                    <Link to="/">
                        <img className='w-[150px]' src={logoImg} alt="" />
                    </Link>
                </div>

                {/* Services */}
                <div className="w-full text-white 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                    <p className='text-[22px] font-[600]'>Services</p>

                    <div className="w-full flex flex-col gap-y-3 mt-5">
                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Ride Hailing</p>
                        </Link>

                        <Link to="/car-rentals">
                            <p className='text-[14px] font-[400]'>Car Rentals</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Bus Ride</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Delivery</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Become A Driver</p>
                        </Link>
                    </div>
                </div>

                {/* About */}
                <div className="w-full text-white 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                    <p className='text-[22px] font-[600]'>About Us</p>

                    <div className="w-full flex flex-col gap-y-3 mt-5">
                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Buzlift</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Privacy Policy</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Terms & Conditions</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>Help</p>
                        </Link>
                    </div>
                </div>

                {/* contact info */}
                <div className="w-full text-white 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                    <p className='text-[22px] font-[600]'>Contact</p>

                    <div className="w-full flex flex-col gap-y-3 mt-5">
                        <Link to="tel: +233553151971">
                            <p className='text-[14px] font-[400]'>Tel: +233 (0) 55 315 1971</p>
                        </Link>

                        <Link to="mailto: info@buzlift.com">
                            <p className='text-[14px] font-[400]'>Email: info@buzlift.com</p>
                        </Link>

                        <Link to="#">
                            <p className='text-[14px] font-[400]'>35 Cresent, East Legon, Accra - Ghana</p>
                        </Link>
                    </div>
                </div>
            </div>

            <hr className='my-5' />

            <div className="w-full flex justify-between items-center">
                <div>
                    <p className='text-[12px] text-white font-[400]'>&copy; 2024 Buzlift</p>
                </div>

                <div className='flex gap-5 items-center text-white'>
                    <Link to="#">
                        <FaFacebookF className='h-5 w-5' />
                    </Link>
                    <Link to="#">
                        <FaInstagram className='h-5 w-5' />
                    </Link>
                    <Link to="#">
                        <FaXTwitter className='h-5 w-5' />
                    </Link>
                    <Link to="#">
                        <FaWhatsapp className='h-5 w-5' />
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CustomFooter
