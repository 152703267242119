import React from 'react'
import CustomStickyNav from '../../components/layouts/customStickyNav'
import rideImg from "../../assets/images/ride-home.jpg"
import busrideImg from "../../assets/images/busrideImg.jpg"
import rentImg from "../../assets/images/rentImg.jpg"
import womanImg from "../../assets/images/womanImg.jpg"
import deliverImg from "../../assets/images/deliverImg.jpg"
import carpoolImg from "../../assets/images/carpoolImg.jpg"
import GetTheAppsComponent from '../../components/shared/getTheAppsComponent'
import DriverBannerComponent from '../../components/shared/driverBannerComponent'
import CustomFooter from '../../components/layouts/customFooter'
import { useNavigate } from 'react-router-dom'


const Homepage = () => {

    const navigate = useNavigate()

    return (
        <>
            <div className="h-screen w-full">
                <div className="w-full">
                    <CustomStickyNav />

                    {/* content */}
                    <div className="content-container">
                        {/* ride hailing */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px]">
                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-20 py-10 items-center mt-10">
                                <div className="w-full">
                                    <p className='text-[46px] leading-[50px] font-[600]'>On-Demand Rides, Anytime</p>
                                    <p className='text-[16px] font-[400] mt-10'>
                                        Get a ride anytime, anywhere with our reliable ride-hailing service. Whether you're commuting to work or heading out for a night on the town, Buzlift has you covered with safe and efficient transportation.
                                    </p>

                                    <div className="mt-10">
                                        <button className='2xl:w-[250px] xl:w-[250px] lg:w-[250px] md:w-[200px] h-[50px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                                            <p>Book a Ride</p>
                                        </button>
                                    </div>
                                </div>

                                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[500px] rounded-[10px] object-cover' src={rideImg} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* get the apps */}
                        <GetTheAppsComponent />

                        {/* bus ride */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px]">
                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-20 py-10 items-center mt-10">
                                <div className="w-full">
                                    <img className='h-[500px] rounded-[10px] object-cover' src={busrideImg} alt="" />
                                </div>

                                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <p className='text-[46px] leading-[50px] font-[600]'>Glide in Comfort, Arrive in Style</p>
                                    <p className='text-[16px] font-[400] mt-10'>
                                        Travel in comfort with our bus services. Ideal for long-distance travel or group outings, our buses are designed to offer a smooth and relaxing journey to your destination.
                                    </p>

                                    <div className="mt-10">
                                        <button className='2xl:w-[250px] xl:w-[250px] lg:w-[250px] md:w-[200px] h-[50px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                                            <p>Get Started</p>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* car rentals */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px]">
                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-2 gap-20 py-10 items-center mt-10">
                                <div className="w-full">
                                    <p className='text-[46px] leading-[50px] font-[600]'>Drive Your Way, On Your Terms</p>
                                    <p className='text-[16px] font-[400] mt-10'>
                                        Rent a car on your terms with our flexible rental options. Whether you need a vehicle for a few hours or a few days, Buzlift offers a wide range of cars to suit your needs.
                                    </p>

                                    <div className="mt-10">
                                        <button className='2xl:w-[250px] xl:w-[250px] lg:w-[250px] md:w-[200px] h-[50px] px-5 rounded-[30px] bg-defaultBlue text-white' onClick={() => navigate("/car-rentals")}>
                                            <p>Rent A Car</p>
                                        </button>
                                    </div>
                                </div>

                                <div className="w-full 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[500px] rounded-[10px] object-cover' src={rentImg} alt="" />
                                </div>
                            </div>
                        </div>

                        {/* want to be a driver */}
                        <DriverBannerComponent />

                        {/* more */}
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] mt-20">
                            <p className='text-[32px] font-[600]'>Explore our range of services</p>

                            <div className="w-full 2xl:grid xl:grid lg:grid md:grid grid-cols-3 gap-10 mt-10">
                                {/* Carpooling */}
                                <div className="w-full bg-white shadow-md rounded-[10px] pb-5">
                                    <img className='h-[200px] object-cover w-full rounded-t-[10px]' src={carpoolImg} alt="" />

                                    <div className="mt-5 px-5">
                                        <p className='text-[22px] leading-[30px] font-[600]'>Share the Ride, Share the Savings</p>
                                        <p className='text-[14px] font-[400] mt-3'>
                                            Save money with our carpooling services. Share rides with others heading in the same direction and contribute to a greener planet.
                                        </p>

                                        <div className="mt-5">
                                            <button className='w-full h-[40px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                                                <p>Get Started</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* Deliver */}
                                <div className="w-full bg-white shadow-md rounded-[10px] pb-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[200px] object-cover w-full rounded-t-[10px]' src={deliverImg} alt="" />

                                    <div className="mt-5 px-5">
                                        <p className='text-[22px] leading-[30px] font-[600]'>Swift Deliveries, Right on Time</p>
                                        <p className='text-[14px] font-[400] mt-3'>
                                            Trust us to deliver your packages swiftly and securely. From small parcels to larger items, our delivery service ensures your goods reach their destination on time.
                                        </p>

                                        <div className="mt-5">
                                            <button className='w-full h-[40px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                                                <p>Get Started</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                {/* other services */}
                                <div className="w-full bg-white shadow-md rounded-[10px] pb-5 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-10">
                                    <img className='h-[200px] object-cover w-full rounded-t-[10px]' src={womanImg} alt="" />

                                    <div className="mt-5 px-5">
                                        <p className='text-[22px] leading-[30px] font-[600]'>Beyond Rides: Your Transportation Hub</p>
                                        <p className='text-[14px] font-[400] mt-3'>
                                            Explore our full range of services, from specialized transport to custom logistics solutions. Whatever your transportation needs might be.
                                        </p>

                                        <div className="mt-5">
                                            <button className='w-full h-[40px] px-5 rounded-[30px] bg-defaultBlue text-white'>
                                                <p>Explore</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="mt-20">
                            <CustomFooter />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homepage
