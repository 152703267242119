import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { GeneralContextProvider } from "./context/generalContext";
import Homepage from "./pages/home/homepage";
import CarRentalsPage from "./pages/rentals";
import OwnerDashboard from "./pages/rentals/owner/ownerDashboard";
import SigninPage from "./pages/authentication/signinPage";



function App() {

  return (
    <>
      <GeneralContextProvider>
        <Router>
          <Routes>
            <Route path="/*" element={<h4>Page not found!</h4>} />
            <Route path="/login" element={<SigninPage />} />

            <Route path="/" element={<Homepage />} />
            <Route path="/car-rentals" element={<CarRentalsPage />} />
            <Route path="/car-rentals/owner/dashboard/:id" element={<OwnerDashboard />} />
          </Routes>
        </Router>
      </GeneralContextProvider>
    </>
  );
}

export default App;
