import React from 'react'
import { Form, Input, DatePicker } from 'antd';

const { Item } = Form;


const SearchVehicleForm = () => {

    const onFinish = (values: any) => {
        console.log('Form values:', values);
        // Handle form submission logic here
    };

    return (
        <>
            <Form
                name="searchForm"
                layout="vertical"
                onFinish={onFinish}
                style={{ maxWidth: 400 }}
            >
                <Item
                    name="location"
                    rules={[{ required: true, message: 'Please enter a location!' }]}
                >
                    <Input className='h-[60px] w-full rounded-[50px]' placeholder="Enter location" />
                </Item>

                <div className="grid grid-cols-3 gap-5 mt-5">
                    <Item
                        className='col-span-2 h-[40px]'
                        name="date"
                        rules={[{ required: true, message: 'Please select a date!' }]}
                    >
                        <DatePicker className='h-[60px] w-full rounded-[50px]' />
                    </Item>

                    <Item
                        name="totalSeats"
                        rules={[{ required: true, message: 'Required!' }]}
                    >
                        <Input type='number' min={1} className="h-[60px] w-full rounded-[50px]" placeholder="Seats" />
                    </Item>
                </div>

                <Item>
                    <button className='h-[60px] w-full rounded-[50px] bg-blue-500 text-white mt-5'>
                        Start Search
                    </button>
                </Item>
            </Form>
        </>
    )
}

export default SearchVehicleForm
