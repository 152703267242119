import axios from "axios";
import { API_BASE_URL, BUZZ_BASE_URL } from "./constants";
import { StorageBox } from "./stroage";

const token = StorageBox.getAccessToken();

//buzsquare
export const BaseServices = axios.create({
  baseURL: `${BUZZ_BASE_URL}`,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

export const BaseServicesDefault = axios.create({
  baseURL: `${BUZZ_BASE_URL}`,
});

export const BaseServicesPostForm = axios.create({
  timeout: 10000000,
  baseURL: `${BUZZ_BASE_URL}`,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Token ${token}`,
  },
});

//buzlift
export const BaseLiftServices = axios.create({
  baseURL: `${API_BASE_URL}`,
  headers: token
    ? {
        Authorization: `Bearer ${token}`,
      }
    : {},
});
