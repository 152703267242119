import React from 'react'
import { Select } from 'antd'


interface theProps {
    onChange?: (data: any) => void
}


const FuelTypeSelector = ({ onChange }: theProps) => {
    return (
        <>
            <Select
                className='h-[40px] w-full'
                options={[
                    { label: 'Petrol', value: 'Petrol' },
                    { label: 'Diesel', value: 'Diesel' },
                    { label: 'Gas (LPG)', value: 'Gas (LPG)' }
                ]}
                onChange={onChange}
                placeholder="Choose"
            />
        </>
    )
}

export default FuelTypeSelector
