import React, { useState } from 'react'
import CustomFooter from '../../../components/layouts/customFooter'
import OverallEarningsCard from '../../../components/rentals/overallEarningsCard'
import MyFleetCard from '../../../components/rentals/myFleetCard'
import OrdersCard from '../../../components/rentals/ordersCard'
import CarListingsTable from '../../../components/rentals/carListingsTable'
import ListCarDrawer from '../../../components/rentals/listCarDrawer'
import { useGeneralContext } from '../../../context/generalContext'
import CustomStickyNav from '../../../components/layouts/customStickyNav'

const OwnerDashboard = () => {

    const { reFetch, setReFetch } = useGeneralContext()
    const [openCreate, setOpenCreate] = useState(false)

    return (
        <>
            <div className="h-screen w-full">
                <div className="w-full bg-defaultBlue bg-opacity-[2%]">
                    <CustomStickyNav rentalsActive />

                    {/* content */}
                    <div className="content-container">
                        <div className="2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] text-black mt-5">
                            <p className='text-[20px] font-[600]'>My Dashboard</p>

                            {/* top */}
                            <div className="mt-5 2xl:grid xl:grid lg:grid md:gird gap-10 grid-cols-4">
                                {/* left */}
                                <div className="w-full col-span-3">
                                    <div className="w-full bg-white shadow-md rounded-[10px] p-5 flex gap-10 items-center">
                                        <div className='2xl:w-[60%] xl:w-[60%] lg:w-[60%] md:w-[60%] pl-10'>
                                            <p className='text-[20px] font-[600]'>List your car today, make your first cash</p>
                                            <p className='text-[12px] font-[400] text-gray-500 mt-5'>
                                                Turn your idle vehicles into a revenue stream by listing them for rent with Buzlift. Whether you're an individual car owner or an agency managing a fleet, we offer a seamless platform to showcase your vehicles to a broad audience.
                                            </p>

                                            <div className="mt-5 2xl:w-[200px] xl:w-[200px] lg:w-[200px] md:w-[200px]">
                                                <button className='w-full h-[40px] text-[14px] rounded bg-defaultBlue text-white' onClick={() => setOpenCreate(true)}>
                                                    <p>Start Listing</p>
                                                </button>
                                            </div>
                                        </div>

                                        <div className='hidden 2xl:block xl:block lg:block md:block'>
                                            <img className='w-[220px]' src="https://static.vecteezy.com/system/resources/previews/011/381/964/original/young-man-giving-the-rental-car-key-to-the-consumer-3d-character-illustration-png.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                                {/* right */}
                                <div className="w-full col-span-1 2xl:mt-0 xl:mt-0 lg:mt-0 md:mt-0 mt-5">
                                    <div className="w-full bg-white shadow-md rounded-[10px] p-5 flex flex-col items-center justify-center">
                                        <div>
                                            <img className='h-20 w-20 object-cover rounded-full' src="https://img.freepik.com/premium-photo/modern-automobile-classic-technology-wheel-traffic_665346-119.jpg" alt="" />
                                        </div>

                                        <div className="mt-3 text-center">
                                            <p className='text-[16px] font-[600]'>Sammy V Rentals</p>
                                            <p className='text-[14px] font-[400] text-gray-500'>samvrentails@gmail.com</p>
                                            <p className='text-[14px] font-[400] text-gray-500'>+233 24 736 0007</p>
                                        </div>

                                        <div className="mt-5 w-full">
                                            <button className='w-full h-[40px] rounded border border-defaultBlue text-[14px] text-defaultBlue hover:bg-defaultBlue hover:text-white'>
                                                <p>Edit Info</p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* table and stats */}
                            <div className="mt-10 2xl:grid xl:grid lg:grid md:gird gap-10 grid-cols-4">
                                {/* left */}
                                <div className="w-full col-span-3">
                                    <div className="w-full bg-white shadow-md rounded-[10px] p-5">
                                        <p className='text-[20px] font-[600]'>My Fleet</p>

                                        <div className="mt-5">
                                            <CarListingsTable />
                                        </div>
                                    </div>
                                </div>

                                {/* right */}
                                <div className="w-full col-span-1">
                                    <OverallEarningsCard />

                                    <div className="mt-5">
                                        <MyFleetCard />
                                    </div>

                                    <div className="mt-5">
                                        <OrdersCard />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='mt-20'>
                            <CustomFooter />
                        </div>
                    </div>

                </div>
            </div>


            {
                openCreate && <ListCarDrawer isOpened={openCreate} handleClose={() => setOpenCreate(false)} handleReFetch={() => setReFetch(!reFetch)} />
            }
        </>
    )
}

export default OwnerDashboard
