import React from 'react'
import CarListingCard from './carListingCard';


const OurFleet = () => {

    const template = Array.from({ length: 6 })

    return (
        <>
            <div className='2xl:px-[250px] xl:px-[120px] lg:px-[100px] md:px-[30px] px-[20px] mt-10 bg-defaultBlue bg-opacity-[15%] py-20'>
                <p className='text-[46px] leading-[50px] font-[600] text-center'>Our Fleet</p>
                <p className='text-[16px] font-[400] mt-5 text-center 2xl:w-[750px] xl:w-[750px] lg:w-[600px] md:w-[500px] flex mx-auto'>
                    Explore our extensive fleet of vehicles designed to meet every travel need and preference. Whether you're planning a weekend getaway, need a reliable car for a business trip, or require a spacious van for a family vacation, Buzlift has the perfect vehicle for you
                </p>

                <div className="mt-10 2xl:grid xl:grid lg:grid md:grid grid-cols-3 gap-8">
                    {
                        template.map((item, i) => (
                            <CarListingCard key={i} />
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default OurFleet
